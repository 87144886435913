
.wbs__web_footer {
  margin-top: 10px;
  width: 100%;
  text-align: center;
  font-size: 0.85em;
  padding-top: 20px;
  padding-bottom: 10px;
}

.footer-item {
  white-space: nowrap;
}
