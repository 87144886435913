.error {
  /* min-height: 230px; */
  h2 {
    font-size: 1.5em;
    margin: 0 0 20px 0;
    padding: 0;
    line-height: normal;
    color: #ee1c24;
  }

  h2.blue {
    color: #00aef0 !important;
  }

  p {
    font-size: 1em;
    line-height: 1.5em;
  }

  p.target {
    white-space: pre-line;
    word-break: break-all;
    word-wrap: break-word;
  }

  p.code {
    font-family: "Andale Mono", "Lucida Console", "Courier New", monospace;
    margin: 0 0 1.5em 0;
  }

  p.shift {
    padding-left: 35px;
  }

  .red {
    color: #ee1c24;
  }

  p.impatient {
    font-family: "Georgia", "Times New Roman", serif;
    font-size: 0.75em;
    margin: 0 0 1.5em 450px;
    white-space: nowrap;
  }
}

.error-border {
  /* border-bottom: 1px solid #ccc; */
  max-width: 450px;
  min-height: 204px;
  margin: 70px auto;
}
