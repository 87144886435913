/* we need this button to be able to get <form onSubmit> request on ENTER */
.hidden-submit-btn {
  position: absolute;
  left: -9999px;
  width: 1px;
  height: 1px;
}

.beta-promo {
  text-align: right;

  @media (min-width: 600px) {
    margin-bottom: -20px;
  }
}

a.dropdown-item {
  color: #555;
  display: block;
  padding: 3px 3px 3px 20px;
}

a.dropdown-item:hover {
  background: #efefef;
  text-decoration: none;
}
