.s2xx {
  &:hover {
    color: #428bca;
  }

  color: #83aac9;
}

.s3xx {
  &:hover {
    color: green;
  }

  color: #408040;
}

.s4xx {
  &:hover {
    color: orange;
  }

  color: #ffd080;
}

.s5xx {
  &:hover {
    color: red;
  }

  color: #ff8080;
}
