@import "../_fallback";

.changes-heatmap-fallback {
  .changes-heatmap-fallback-note {
    .fallback-panel();

    @media (max-width: 600px) {
      margin: 10px 0 75px 0;
    }

    @media (min-width: 600px) {
      margin: 10px 0 10px 0;
    }

    height: 150px;
    max-width: 559px;
    min-width: 100%;
  }

  .changes-heatmap-fallback-item-headline {
    .fallback-panel();

    height: 26px;
    width: 100px;
    margin: 20px 0 10px;
  }

  .changes-heatmap-fallback-container {
    .fallback-panel();

    height: 217px;
    max-width: 559px;
    min-width: 100%;
    margin: 0 0 10px 0;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
}
