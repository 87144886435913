.view-navbar {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  margin: 24px 0;
  line-height: 1.7;

  .selected-option {
    span {
      color: #fff;
      background-color: #be1318; // red - color of logo
      //background-color: #2b8dd1; // blue - the same color as unselected but inverted
    }
  }

  .navbar-option {
    &:not(:first-of-type)::before {
      content: " \00B7 ";
      margin: 0 6px;
    }

    a,
    span {
      border-radius: 10px;
      padding: 7px;
      text-decoration: none;
      white-space: nowrap;
    }
  }

  .not-selected-option {
    a,
    span {
      &:hover {
        background-color: #e8e8e8;
      }
    }
  }

  @media (max-width: 600px) {
    position: sticky;
    top: 46px;
    z-index: 40;
    background-color: white;
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px #f0f0f0 solid;
  }
}

.beta-badge-container {
  position: relative;
}

.beta-feature::after {
  content: "beta";
  color: #e2c10ce6;
  font-size: 0.6em;
  font-weight: bold;
  position: absolute;
  right: 2px;
  top: -8px;
}
