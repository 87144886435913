@import "../_fallback";

.summary-fallback {
  .summary-fallback-headline {
    .fallback-panel();

    height: 41px;
    margin: 0 0 40px 0;
  }

  .summary-fallback-item {
    .fallback-panel();

    height: 200px;
    margin: 0 0 20px 0;
  }
}
