@import "../_fallback";

.calendar-fallback {
  max-width: 990px;
  margin: 28px auto 0 auto;
  min-height: 642px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.calendar-month-fallback {
  .fallback-panel();

  width: 210px;
  height: 170px;
  display: inline-block;
  border-spacing: 0;
  margin: 0 15px 40px 15px;
}
