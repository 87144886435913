.fallback-panel {
  background: #f4f4f4;
  background: linear-gradient(135deg, #fafafa 45%, #fff 50%, #f0f0f0 55%);
  animation: fallback-panel-animation 5s ease-in-out infinite;
  background-position: 0 0;
  background-size: 800% 800%;

  &:nth-child(2n) {
    animation-delay: 0.25s;
    opacity: 0.5;
  }

  &:nth-child(3n) {
    animation-delay: 0.5s;
  }

  &:nth-child(4n) {
    animation-delay: 0.75s;
    opacity: 0.5;
  }

  &:nth-child(5n) {
    animation-delay: 1s;
  }

  &:nth-child(6n) {
    animation-delay: 1.25s;
    opacity: 0.5;
  }

  &:nth-child(7n) {
    animation-delay: 1.5s;
  }

  &:nth-child(8n) {
    animation-delay: 1.75s;
    opacity: 0.5;
  }

  &:nth-child(9n) {
    animation-delay: 2s;
  }

  &:nth-child(10n) {
    animation-delay: 2.25s;
    opacity: 0.5;
  }

  &:nth-child(11n) {
    animation-delay: 2.5s;
  }

  &:nth-child(12n) {
    animation-delay: 2.75s;
    opacity: 0.5;
  }
}

@keyframes fallback-panel-animation {
  0% {
    background-position: 0% 53%;
  }

  50% {
    background-position: 100% 48%;
  }

  100% {
    background-position: 0% 53%;
  }
}
