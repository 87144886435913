#donation-link {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;

  a {
    display: block;
    margin: 0 10px 3px 0;
  }
  a.donation-button {
    margin-left: 9px;
  }
}

@media (max-width: 600px) {
  #donation-link {
    a {
      margin: 0 0 10px 0;
    }
  }
}
