@import '../_fallback';

.search-result-fallback-item {
  .fallback-panel();

  @media (max-width: 600px) {
    height: 311px;
  }

  @media (min-width: 600px) {
    height: 132px;
  }

  margin: 0 0 10px 0;
}
