// TODO: bootstrap css overwrites archive.min.css style.
// So here some critical styles from https://archive.org/includes/archive.min.css
// to bring them back.
// We should use sources of bootstrap to create our custom style
// but bed side that bootstrap moved from less to scss.
// So we should setup scss compiler to webpack.

html,
body {
  //font-size: 14px;
  margin: 0;
  padding: 0;
}

a {
  color: #428bca;
}

#faux-header {
  height: 50px;
  width: 100%;
  margin: 0;
  background-color: black;
}

#react-wayback-search {
  max-width: 95% !important;
  margin: 0 auto;
  padding: 10px;
}

/*
Fix of google bug "Font Boosting" https://bugs.webkit.org/show_bug.cgi?id=FontBoostinghttps://bugs.webkit.org/show_bug.cgi?id=FontBoosting
https://stackoverflow.com/a/15261825/1324730
 */
body,
body * {
  max-height: 1000000px;
}
