@import "../_fallback";

.revolving-landing-fallback {
  .fallback-panel();

  height: 100px;
  margin: 40px 0 70px 0;
}

.feature-list-fallback {
  display: flex;
  justify-content: space-between;

  @media (max-width: 600px) {
    flex-direction: column;
  }
}

.feature-list-item-fallback {
  .fallback-panel();

  @media (max-width: 600px) {
    width: 100%;
    height: 240px;
    margin: 20px 0;
  }

  @media (min-width: 600px) {
    width: 33.3%;
    height: 210px;
    margin: 10px 10px 24px 10px;
  }
}
