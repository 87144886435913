@import "../_captures-color-scheme";

.wb-calendar-notes {
  width: 70%;
  margin: 25px auto 0 auto;
  text-align: left;

  .color-codes-notes {
    font-family: "Georgia", "Times New Roman", serif;
    font-size: 1.25em;
    line-height: 1.5em;
    list-style: none;
    padding: 0;
  }

  h2 {
    font-family: "Arial", "Helvetica", sans-serif;
    font-size: 1.5em;
    margin: 0;
    padding: 0;
    line-height: normal;
    color: #00aef0;
  }

  p {
    font-family: "Georgia", "Times New Roman", serif;
    font-size: 1.25em;
    line-height: 1.5em;
    color: #666;
  }
}
