@import '../_fallback';

.collections-layout-fallback {
  .collections-layout-fallback-item {
    .fallback-panel();

    width: 100%;
    height: 20px;
    margin: 2px 0;
  }

  margin: 16px 0 160px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
