@import "../_fallback";

.welcome-panel-fallback {
  margin: 21px 0;

  .welcome-panel-fallback-item {
    .fallback-panel();

    @media (max-width: 600px) {
      width: 100%;
      height: 192px;
    }

    @media (min-width: 600px) {
      width: calc(33% - 50px);
      height: 200px;
    }

    margin: 30px 25px;
  }

  display: flex;
  flex-wrap: wrap;
}
