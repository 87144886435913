@import "../_fallback";

.sitemap-layout-fallback {
  .sitemap-layout-fallback-headliner {
    .fallback-panel();

    height: 41px;
    width: 100px;
    margin: 0 0 10px 0;
  }

  .sitemap-layout-fallback-note {
    .fallback-panel();

    height: 120px;
    width: 100%;
    margin: 0 0 10px 0;
  }

  .sitemap-layout-fallback-content {
    .fallback-panel();

    height: 300px;
    width: 100%;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
}
